import React, { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../components/Button';
import { cleanTelStr, Input, TelInput } from '../../components/FormControls';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

import OrangeImage from '../../components/images/JuppukOrangeImage';
import RayImage from '../../components/images/JuppukRayImage';
import RayTalking from '../../components/images/JuppukRayTalkingImage';

import styles from '../juppuk-new-years.module.scss';

const JuppukNewYearsPage = ({ location }) => {
  const [enquireForm, setEnquireForm] = useState({
    name: '',
    tel: ''
  });
  const [isEnquiring, setIsEnquiring] = useState(false);
  const [isEnquireSuccess, setIsEnquireSuccess] = useState(false);

  const shouldAllowSubmit = useMemo(
    () => !!(enquireForm.name.trim() && cleanTelStr(enquireForm.tel).length === 8 && !isEnquiring),
    [enquireForm.name, enquireForm.tel, isEnquiring]
  );

  const submit = useCallback(async () => {
    if (isEnquiring || isEnquireSuccess || !shouldAllowSubmit) {
      return;
    }

    setIsEnquiring(true);

    try {
      const { name, tel } = enquireForm;
      await axios({
        method: 'post',
        url: '/api/contact',
        data: {
          name: name.trim(),
          tel: cleanTelStr(tel)
        }
      });
      setIsEnquireSuccess(true);
    } catch (err) {
      setIsEnquiring(false);
    }
  }, [
    enquireForm.name,
    enquireForm.tel,
    isEnquiring,
    isEnquireSuccess,
    shouldAllowSubmit,
    setIsEnquiring,
    setIsEnquireSuccess
  ]);

  const onChange = useCallback(
    ({ target }) => {
      const { name, value } = target;
      setEnquireForm({ ...enquireForm, [name]: value });
    },
    [enquireForm.name, enquireForm.tel, setEnquireForm]
  );

  return (
    <Layout className={styles.root} disablePromoPopup location={location} navAlwaysFixed showI18n>
      <SEO title="DOUBLE STORAGE 儲存 X 收納服務優惠" />
      <section className={styles.lead}>
        <div className={styles.title}>
          <div>聯手推出</div>
          <h1>X2 DOUBLE STORAGE</h1>
        </div>
        <div className={cx(styles.companyName, styles.juppuk)}>
          <div className={styles.zh}>執屋</div>
          <div className={styles.en}>JuppUk</div>
        </div>
        <div className={cx(styles.companyName, styles.roomyr)}>
          <div className={styles.zh}>儲方</div>
          <div className={styles.en}>Roomyr</div>
        </div>
        <div className={styles.orange}>
          <OrangeImage />
        </div>
        <div className={styles.ray}>
          <RayImage />
        </div>
      </section>
      <section className={styles.intro}>
        <div className={styles.wrapper}>
          <div className={styles.bubble}>
            <span>
              新年快樂！祝各位新一年都有一個好開始！許久沒甚新搞作，新年伊始，萬象更新，我們率先為大家預備了份大驚喜！
              這次 ROOMYR 找來鼎鼎大名，各大電視及電台傳媒均爭相採訪的收納達人—
            </span>
            <span className={styles.highlight}>阿橙</span>
            <span>！</span>
          </div>
          <div className={styles.rayTalking}>
            <RayTalking />
          </div>
        </div>
      </section>
      <section className={styles.details}>
        <div className={styles.wrapper}>
          <p>
            ROOMYR 與阿橙聯手推出DOUBLE STORAGE 儲存 X 收納服務優惠！期間限定，年尾大掃除很苦惱?
            不怕！有阿橙專業重整您的家居，聯同儲方提供的外置雜物房，再加上我們的搬運服務，從頭至尾一站式服務完美配搭！交給我們，為您一一辦妥！
          </p>

          <p>星級主任阿Ray及收納達人阿橙將會親身跟進此聯乘推廣計劃的個案，請速速致電阿Ray預定:</p>

          <a href="tel:21113113" className={styles.phone}>
            <FontAwesomeIcon fixedWidth icon={['fal', 'phone']} />
            <span>2111 3113</span>
          </a>

          <p style={{ textAlign: 'center' }}>新年將近，檔期有限，請即行動！</p>
        </div>
      </section>
      <section className={styles.enquire}>
        <div className={styles.wrapper}>
          <h2 className={styles.title}>想立即獲取報價？</h2>
          <p>請輸入以下簡單資料，我們的服務主任將於24小時內來電了解您的需要，並為您推薦最合適的儲存方案。</p>
          <div className={styles.form}>
            <Input name="name" label="姓名" value={enquireForm.name} onChange={onChange} />
            <TelInput name="tel" label="電話" value={enquireForm.tel} disabled={isEnquiring} onChange={onChange} />
            <Button tabIndex={0} disabled={!shouldAllowSubmit} onClick={submit}>
              聯絡我們
            </Button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default JuppukNewYearsPage;
